<template>
  <img v-if="!!image" :src="imageUrl" :alt="alt" class="w-100 pointer max-h-300px" :class="imageClass" :id="image.id"
       data-toggle="modal" :data-target="target" @click="$emit('clicked', $event)">
</template>

<script>
import store from "@/store";

export default {
  props: {
    image: {required: true},
    target: {default: '#bid_images'},
    imageClass: {}
  },
  emits: ['clicked'],
  computed: {
    alt() {
      return !!this.image && !!this.image.name ? this.image.name : 'صورة المزاد';
    },
    imageUrl() {
      if(!this.image?.path) {
        return '';
      }

      return store.getters.publicPath + '/storage/' + this.image.path;
    }
  }
}
</script>